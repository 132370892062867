import React from 'react';
import { Heading, Center } from '@chakra-ui/react';

import Layout from '@components/Layout';

export default function Home() {
  return (
    <Layout>
      <Center height="100vh">
        <Center>
          <Heading textAlign="center" fontFamily="customFont" fontSize="27px">Coming Soon!</Heading>
        </Center>
      </Center>
    </Layout>
  );
}